import React, { useContext } from "react";
import "./fiftyFifty.css";
import { BreakpointContext } from "../../../contexts/windowSize/WindowSize";
import Button from "../../../ui/Button";

const FiftyFifty = ({ data }) => {
   const { windowSize } = useContext(BreakpointContext);
   const mobile = windowSize.width < 1200;
   if (!data) return null;
   return (
      <div className="fiftyFifty">
         {data.map((section, index) => {
            let article;
            switch (section.acf_fc_layout) {
               case "img_table":
                  article = TableConstructor(section.table, mobile);
                  break;
               case "img_text":
                  article = TextConstructor(section.text, mobile);
                  break;
               default:
                  article = null;
                  break;
            }
            return (
               <section className="fiftyBlockContainer" key={index}>
                  {article}
                  {ImgConstructor(section.img, mobile)}
               </section>
            );
         })}
      </div>
   );
};

const ImgConstructor = (imgObj, mobile) => {
   const { img, framed, order, mobile_order } = imgObj;

   const currentOrder = mobile ? mobile_order : order;
   return (
      <article
         className={`article img_article ${framed ? "framed" : ""}`}
         style={{ order: currentOrder }}
      >
         <img src={img.url} alt="Electras Naglar" />
      </article>
   );
};

const TextConstructor = (text, mobile) => {
   const { header, link_id, mobile_order, order, section_number, ingress } = text;
   const currentOrder = mobile ? mobile_order : order;

   return (
      <article className="article text_article" style={{ order: currentOrder }}>
         <span className="linkDestination" id={link_id} />
         <h3 className="welcomeHeader">
            <span className="headerNr">{section_number}</span>
            <span className="headerHeader">{header}</span>
         </h3>
         <div dangerouslySetInnerHTML={{ __html: ingress }} />
      </article>
   );
};

const TableConstructor = (table, mobile) => {
   const { columns, header, link_id, mobile_order, order, section_number } = table;

   const currentOrder = mobile ? mobile_order : order;

   return (
      <article className="article table_article" style={{ order: currentOrder }}>
         <span className="linkDestination" id={link_id} />
         <h3 className="welcomeHeader">
            <span className="headerNr">{section_number}</span>
            <span className="headerHeader">{header}</span>
         </h3>
         <div className="table">
            {columns.map((col, index) => {
               return (
                  <div className="column" key={index}>
                     <p className="col_title">{col.title}</p>
                     {col.rows.map((row, row_index) => {
                        return (
                           <p className="row" key={row_index}>
                              {row.text}
                           </p>
                        );
                     })}
                  </div>
               );
            })}
         </div>
         <a
            href={table.button.url}
            target="_blank"
            rel="noopener noreferrer"
            className="tableBtn"
         >
            <Button>{table.button.title}</Button>
         </a>
      </article>
   );
};

export default FiftyFifty;
