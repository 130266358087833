import React from "react";
import "./text.css";
const Text = ({ data }) => {
   return (
      <div className="simpleText">
         <h3>{data}</h3>
      </div>
   );
};

export default Text;
