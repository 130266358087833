import React from "react";
import "./singularImg.css";
const SingularImg = ({ data }) => {
   return (
      <div className="singularImg">
         <img src={data.url} alt="Electras Naglar" />
      </div>
   );
};

export default SingularImg;
