import React from "react";
import "./button.css";

const Button = ({ handleClick = null, children }) => {
   return (
      <button className="button" onClick={handleClick}>
         {children}
      </button>
   );
};

export default Button;
