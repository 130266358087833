import React, { useState, useEffect } from "react";
import WindowSize from "./contexts/windowSize/WindowSize";
import "./normalizer.css";
import "./appStyle.css";
import Axios from "axios";

// components
import Header from "./components/header/Header";
import Main from "./components/main/Main";
import Footer from "./components/footer/Footer";
import LoadingScreen from "./components/main/LoadingScreen/LoadingScreen";

function App() {
   const [data, setData] = useState(null);
   const [loading, setLoading] = useState(true);

   useEffect(() => {
      Axios.get("https://hoselectra.se/wp/wp-json/wp/v2/pages/94")
         .then((response) => {
            // handle success
            setData(response.data.acf);
            setLoading(false);
         })
         .catch((error) => {
            // handle error
         });
   }, []);

   return (
      data && (
         <>
            {<LoadingScreen loading={loading} />}
            <WindowSize>
               <div className="app">
                  <Header data={data.header} />
                  <Main data={data} />
                  <Footer data={data.footer} />
               </div>
            </WindowSize>
         </>
      )
   );
}

export default App;
