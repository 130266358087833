import React, { createContext, useState, useEffect } from "react";

export const BreakpointContext = createContext();

const getSize = () => {
   return {
      width: window.innerWidth,
      height: window.innerHeight,
   };
};

const WindowSize = (props) => {
   const [windowSize, setWindowSize] = useState(getSize());
   const [breakPoint, setBreakpoint] = useState(null);

   const handleResize = () => {
      setWindowSize(getSize());
   };

   useEffect(() => {
      window.addEventListener("resize", handleResize);
   }, []);

   document.body.classList.remove("mobile");
   document.body.classList.remove("tablet");
   document.body.classList.remove("desktop");

   if (windowSize.width <= 500) {
      breakPoint !== "mobile" && setBreakpoint("mobile");
      document.body.classList.add("mobile");
   } else if (windowSize.width > 500 && windowSize.width <= 1200) {
      breakPoint !== "tablet" && setBreakpoint("tablet");
      document.body.classList.add("tablet");
   } else {
      breakPoint !== "desktop" && setBreakpoint("desktop");
      document.body.classList.add("desktop");
   }

   return (
      <BreakpointContext.Provider
         value={{
            breakPoint,
            windowSize,
         }}
      >
         {props.children}
      </BreakpointContext.Provider>
   );
};

export default WindowSize;
