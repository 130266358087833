import React, { useContext, useState } from "react";
import { BreakpointContext } from "../../contexts/windowSize/WindowSize";

import "./headerStyle.css";

const Header = ({ data }) => {
   const { breakPoint } = useContext(BreakpointContext);
   const [menuOpened, setMenuOpened] = useState(false);

   const handleClick = () => {
      setMenuOpened(!menuOpened);
   };

   const desktopView = (
      <ul className="desktopView">
         <li className="logoContainer" style={{ order: data.logo.order }}>
            <img src={data.logo.img.url} alt="logo" />
         </li>
         {data.links.map((link, idx) => {
            return (
               <li key={idx} style={{ order: link.order }}>
                  <a href={"#" + link.linkid}>{link.title}</a>
               </li>
            );
         })}
      </ul>
   );
   const notDesktopView = (
      <div className="notDesktopView">
         <div className="logoContainer">
            <img src={data.logo.img.url} alt="logo" />
         </div>
         <div>
            {!menuOpened ? (
               <div className="menuButton" onClick={handleClick}>
                  <div></div>
                  <div></div>
                  <div></div>
               </div>
            ) : (
               <div className="closeMenuBtn" onClick={handleClick}>
                  &#x2715;
               </div>
            )}
            <ul className={"menuOpened" + menuOpened}>
               {data.links.map((link, idx) => {
                  return (
                     <li key={"#" + idx} style={{ order: link.order }}>
                        <a href={"#" + link.linkid} onClick={handleClick}>
                           {link.title}
                        </a>
                     </li>
                  );
               })}
            </ul>
         </div>
      </div>
   );
   return (
      <header className="header">
         {breakPoint === "desktop" ? desktopView : notDesktopView}
      </header>
   );
};
export default Header;
