import React from 'react';
import './welcomeStyle.css';

const Welcome = ({data}) => {
    return (
        <div id={data.linkNr} className="welcome">
            <h3 className="welcomeHeader">
                <span className="headerNr">{data.sectionNr}</span>
                <span className="headerHeader">{data.header}</span>
            </h3>
            <p className="welcomeIngress">{data.ingress}</p>
        </div>
    )
}
export default Welcome;